import { GetDisciplinesDiscipline } from "@platform-app/app/core/api/models";

export interface AgreementModel {
  agreementName: string | null;
  counterpartyOrganizationName: string | null;
  counterpartyOrganizationId: string | null;
  counterpartyOrganizationGoogleId: string | null;
  currentOrganizationName: string | null;
  startDate: string | null;
  endDate: string | null | undefined;
  disciplines: GetDisciplinesDiscipline[] | null;
  contactFirstName?: string | null;
  contactLastName?: string | null;
  contactEmail?: string | null;
  contactPhoneNumber?: string | null;
}

export enum MergeTag {
  ClinicName = "HEALTHCARE_ORGANIZATION_NAME",
  SchoolName = "EDUCATIONAL_INSTITUTION_NAME",
  StartDate = "START_DATE",
  EndDate = "END_DATE",
  Disciplines = "DISCIPLINES",
}

export enum DynamicField {
  CurrentOrganizationName = "currentOrganizationName",
  CounterpartyOrganizationName = "counterpartyOrganizationName",
  StartDate = "startDate",
  EndDate = "endDate",
  Disciplines = "disciplines",
}

export interface MergeTagModel {
  title: string;
  value: MergeTag;
  fieldId: DynamicField;
}

export type DynamicFieldModel =
  | {
      id: DynamicField.Disciplines;
      value: GetDisciplinesDiscipline[] | null;
    }
  | {
      id:
        | DynamicField.CounterpartyOrganizationName
        | DynamicField.CurrentOrganizationName;
      value: string | null;
    }
  | {
      id: DynamicField.StartDate | DynamicField.EndDate;
      value: Date | string | null | undefined;
    };

export interface DynamicFieldUpdatedModel {
  tag: MergeTag;
  value: string | null;
}

export interface DynamicFieldFocusedModel {
  tag: MergeTag;
  focused: boolean;
  source: "editor" | "form";
}

export enum BuilderSidebarTabIndex {
  Templates = 0,
  Fields = 1,
}
