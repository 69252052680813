import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import {
  EDITOR_CONTENT_FILE_TYPE,
  MERGE_TAGS,
} from "@platform-app/app/agreement-builder/shared/constants";
import { EditorDynamicFieldsUtility } from "@platform-app/app/agreement-builder/shared/editor-dynamic-fields.utility";
import {
  AgreementModel,
  DynamicFieldModel,
  DynamicFieldUpdatedModel,
  MergeTagModel,
} from "@platform-app/app/agreement-builder/shared/models";
import {
  AffiliationAgreementData,
  ContactData,
  GetAffiliationAgreementTemplatesTemplate,
  OrganizationData,
  PartnerInvitation,
  PartnerInvitationType,
  UserGroup,
} from "@platform-app/app/core/api/models";
import { AffiliationAgreementsService } from "@platform-app/app/core/api/services";
import { AuthState } from "@platform-app/app/core/auth/auth.state";
import { DateUtility } from "@platform-app/app/main/shared/utilities/date.utility";
import { finalize, switchMap, tap } from "rxjs";

export interface AgreementBuilderStateModel {
  agreement: AgreementModel;
  lastUpdatedFieldModel: DynamicFieldUpdatedModel | null;
  mergeTags: MergeTagModel[];
  templateOptions: GetAffiliationAgreementTemplatesTemplate[];
  selectedTemplateId: string | null;
  builderContent: string | null;
  sendInvitationLoading: boolean;
  saveTemplateContentLoading: boolean;
  renameTemplateLoading: boolean;
}

const defaultState: AgreementBuilderStateModel = {
  agreement: {
    agreementName: null,
    currentOrganizationName: null,
    counterpartyOrganizationName: null,
    counterpartyOrganizationId: null,
    counterpartyOrganizationGoogleId: null,
    startDate: null,
    endDate: null,
    disciplines: null,
    contactFirstName: null,
    contactLastName: null,
    contactEmail: null,
    contactPhoneNumber: null,
  },
  lastUpdatedFieldModel: null,
  mergeTags: MERGE_TAGS,
  templateOptions: [],
  selectedTemplateId: null,
  builderContent: null,
  sendInvitationLoading: false,
  saveTemplateContentLoading: false,
  renameTemplateLoading: false,
};

export class UpdateAgreementField {
  static readonly type = "[Agreement Builder page] Update Agreement Field";
  constructor(public payload: { field: DynamicFieldModel; dynamic: boolean }) {}
}

export class EditorContentUpdated {
  static readonly type = "[Agreement Builder page] Editor Content Updated";
  constructor(public payload: { content: string | null }) {}
}

export class UpdateMergeTagsList {
  static readonly type = "[Agreement Builder page] Update Merge Tags List";
  constructor(public payload: { tags: MergeTagModel[] }) {}
}

export class LoadTemplateOptions {
  static readonly type = "[Agreement Builder page] Load Template Options";
}

export class AgreementTemplateSelected {
  static readonly type = "[Agreement Builder page] Agreement Template Selected";
  constructor(public payload: { templateId: string | null }) {}
}

export class AddAgreementTemplate {
  static readonly type = "[Agreement Builder page] Add Agreement Template";
  constructor(public payload: { templateName: string | null }) {}
}

export class UpdateSelectedAgreementTemplateContent {
  static readonly type =
    "[Agreement Builder page] Update Agreement Template Content";
  constructor(public payload: { templateName: string }) {}
}

export class RenameAgreementTemplate {
  static readonly type = "[Agreement Builder page] Rename Agreement Template";
  constructor(public payload: { templateId: string; templateName: string }) {}
}

export class DeleteAgreementTemplate {
  static readonly type = "[Agreement Builder page] Delete Agreement Template";
  constructor(public payload: { templateId: string }) {}
}

export class SendPartnerInvitation {
  static readonly type = "[Agreement Builder page] Send Partner Invitation";
  constructor(public payload: { invitationMessage: string | null }) {}
}

export class ResetAgreementBuilderState {
  static readonly type =
    "[Agreement Builder page] Reset Agreement Builder State";
}

@State<AgreementBuilderStateModel>({
  name: "AgreementBuilder",
  defaults: defaultState,
})
@Injectable()
export class AgreementBuilderState {
  constructor(
    private readonly affiliationAgreementsService: AffiliationAgreementsService,
    private readonly store: Store,
  ) {}

  @Selector()
  static agreement(state: AgreementBuilderStateModel) {
    return state.agreement;
  }

  @Selector()
  static lastUpdatedField(state: AgreementBuilderStateModel) {
    return state.lastUpdatedFieldModel;
  }

  @Selector()
  static mergeTags(state: AgreementBuilderStateModel) {
    return state.mergeTags;
  }

  @Selector()
  static templateOptions(state: AgreementBuilderStateModel) {
    return state.templateOptions;
  }

  @Selector()
  static selectedTemplateId(state: AgreementBuilderStateModel) {
    return state.selectedTemplateId;
  }

  @Selector()
  static sendInvitationLoading(state: AgreementBuilderStateModel) {
    return state.sendInvitationLoading;
  }

  @Selector()
  static saveTemplateContentLoading(state: AgreementBuilderStateModel) {
    return state.saveTemplateContentLoading;
  }

  @Selector()
  static renameTemplateLoading(state: AgreementBuilderStateModel) {
    return state.renameTemplateLoading;
  }

  @Selector()
  static builderContent(state: AgreementBuilderStateModel) {
    return state.builderContent;
  }

  @Action(UpdateAgreementField)
  updateAgreementField(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: UpdateAgreementField,
  ) {
    const stateSnapshot = ctx.getState();
    const { field, dynamic } = action.payload;

    ctx.patchState({
      agreement: {
        ...stateSnapshot.agreement,
        [field.id]: field.value,
      },
    });

    if (dynamic) {
      const tag = stateSnapshot.mergeTags.find(
        (t) => t.fieldId === field.id,
      )?.value;
      if (tag) {
        const value =
          EditorDynamicFieldsUtility.getStringFromFieldValue(field) ?? null;
        ctx.patchState({
          lastUpdatedFieldModel: {
            tag,
            value,
          },
        });
      }
    }
  }

  @Action(EditorContentUpdated)
  updateBuilderContent(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: EditorContentUpdated,
  ) {
    ctx.patchState({
      builderContent: action.payload.content,
    });
  }

  @Action(UpdateMergeTagsList)
  updateMergeTagsList(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: UpdateMergeTagsList,
  ) {
    ctx.patchState({
      mergeTags: action.payload.tags,
    });
  }

  @Action(LoadTemplateOptions)
  loadTemplateOptions(ctx: StateContext<AgreementBuilderStateModel>) {
    return this.loadAgreementTemplateOptions(ctx);
  }

  @Action(AgreementTemplateSelected)
  agreementTemplateSelected(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: AgreementTemplateSelected,
  ) {
    ctx.patchState({
      selectedTemplateId: action.payload.templateId,
    });
  }

  @Action(AddAgreementTemplate)
  addAgreementTemplate(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: AddAgreementTemplate,
  ) {
    ctx.patchState({ saveTemplateContentLoading: true });

    const { templateName } = action.payload;
    const { builderContent, agreement } = ctx.getState();

    if (!builderContent)
      throw new Error("Builder content is null. Template cannot be saved.");

    const templateContent =
      EditorDynamicFieldsUtility.replaceDynamicFieldsWithDefaults(
        builderContent,
      );

    const htmlFile = new File([templateContent], `${templateName}.html`, {
      type: EDITOR_CONTENT_FILE_TYPE,
    });

    if (!templateName && !agreement.agreementName)
      throw new Error("Template name is missing. Template cannot be saved.");

    const name =
      templateName ??
      this.generateUniqueTemplateName(agreement.agreementName!, ctx);

    return this.affiliationAgreementsService
      .addAffiliationAgreementTemplate({
        body: { Body: { templateName: name }, File: htmlFile },
      })
      .pipe(
        switchMap((response) =>
          this.loadAgreementTemplateOptions(ctx).pipe(
            tap(() =>
              ctx.patchState({ selectedTemplateId: response.addedTemplateId }),
            ),
          ),
        ),
        finalize(() => ctx.patchState({ saveTemplateContentLoading: false })),
      );
  }

  @Action(UpdateSelectedAgreementTemplateContent)
  updateSelectedAgreementTemplateContent(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: UpdateSelectedAgreementTemplateContent,
  ) {
    ctx.patchState({ saveTemplateContentLoading: true });

    const { templateName } = action.payload;
    const { builderContent, selectedTemplateId } = ctx.getState();

    if (!builderContent || !selectedTemplateId)
      throw new Error("Template cannot be saved.");

    const templateContent =
      EditorDynamicFieldsUtility.replaceDynamicFieldsWithDefaults(
        builderContent,
      );

    const htmlFile = new File([templateContent], `${templateName}.html`, {
      type: EDITOR_CONTENT_FILE_TYPE,
    });

    return this.affiliationAgreementsService
      .updateAffiliationAgreementTemplateContent({
        templateId: selectedTemplateId,
        body: { content: htmlFile },
      })
      .pipe(
        finalize(() => ctx.patchState({ saveTemplateContentLoading: false })),
      );
  }

  @Action(RenameAgreementTemplate)
  renameAgreementTemplate(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: RenameAgreementTemplate,
  ) {
    ctx.patchState({ renameTemplateLoading: true });

    const { templateId, templateName } = action.payload;

    return this.affiliationAgreementsService
      .renameAffiliationAgreementTemplate({
        templateId,
        body: { templateName },
      })
      .pipe(
        switchMap(() => this.loadAgreementTemplateOptions(ctx)),
        finalize(() => ctx.patchState({ renameTemplateLoading: false })),
      );
  }

  @Action(DeleteAgreementTemplate)
  deleteAgreementTemplate(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: DeleteAgreementTemplate,
  ) {
    const { templateId } = action.payload;
    const { selectedTemplateId } = ctx.getState();

    return this.affiliationAgreementsService
      .deleteAffiliationAgreementTemplate({ templateId })
      .pipe(
        tap(() => {
          if (selectedTemplateId === templateId)
            ctx.patchState({ selectedTemplateId: null });
        }),
        switchMap(() => this.loadAgreementTemplateOptions(ctx)),
      );
  }

  @Action(SendPartnerInvitation)
  sendPartnerInvitation(
    ctx: StateContext<AgreementBuilderStateModel>,
    action: SendPartnerInvitation,
  ) {
    ctx.patchState({ sendInvitationLoading: true });

    const isCurrentUserSchool =
      this.store.selectSnapshot(AuthState.user)?.roleGroup ===
      UserGroup.SchoolUser;

    const { builderContent, selectedTemplateId, agreement } = ctx.getState();

    if (!builderContent)
      throw new Error("Builder content is null. Invitation cannot be sent.");

    const htmlFile = new File(
      [builderContent],
      `${agreement.agreementName}.html`,
      {
        type: EDITOR_CONTENT_FILE_TYPE,
      },
    );

    const jsonBody = {
      affiliationAgreement: this.getAffiliationAgreementBody(agreement),
      contact: this.getContactDataBody(
        agreement,
        action.payload.invitationMessage,
      ),
      type: PartnerInvitationType.New,
      createdWithBuilder: true,
      builderContentTemplateId: selectedTemplateId,
    } satisfies PartnerInvitation;

    const body = { Invitation: jsonBody, Files: [htmlFile] };

    const request = isCurrentUserSchool
      ? this.affiliationAgreementsService.affiliationAgreementsInvitePartnerClinicPost(
          { body },
        )
      : this.affiliationAgreementsService.affiliationAgreementsInvitePartnerSchoolPost(
          { body },
        );

    return request.pipe(
      finalize(() => ctx.patchState({ sendInvitationLoading: false })),
    );
  }

  @Action(ResetAgreementBuilderState)
  resetAgreementBuilderState(ctx: StateContext<AgreementBuilderStateModel>) {
    ctx.setState(defaultState);
  }

  private getAffiliationAgreementBody(
    agreement: AgreementModel,
  ): AffiliationAgreementData {
    const endDate = agreement.endDate ? new Date(agreement.endDate) : null;

    return {
      name: agreement.agreementName!,
      disciplinesIds: agreement
        .disciplines!.map((d) => d.id)
        .filter((id: number) => id !== 0),
      endDate: DateUtility.convertToDateOnlyString(endDate),
      startDate: DateUtility.convertToDateOnlyString(
        new Date(agreement.startDate!),
      )!,
      organization: {
        id: agreement.counterpartyOrganizationId,
        googlePlaceId: agreement.counterpartyOrganizationGoogleId,
        name: agreement.counterpartyOrganizationName,
      } as OrganizationData,
    } satisfies AffiliationAgreementData;
  }

  private getContactDataBody(
    agreement: AgreementModel,
    message: string | null,
  ): ContactData | null {
    if (agreement.counterpartyOrganizationId) return null;

    return {
      firstName: agreement.contactFirstName!,
      lastName: agreement.contactLastName!,
      email: agreement.contactEmail!,
      phoneNumber: agreement.contactPhoneNumber ?? null,
      message: message!,
    } satisfies ContactData;
  }

  private loadAgreementTemplateOptions(
    ctx: StateContext<AgreementBuilderStateModel>,
  ) {
    return this.affiliationAgreementsService
      .getAffiliationAgreementTemplates()
      .pipe(tap((response) => ctx.patchState({ templateOptions: response })));
  }

  private generateUniqueTemplateName(
    agreementName: string,
    ctx: StateContext<AgreementBuilderStateModel>,
  ): string {
    const baseTemplateName = `${agreementName} Template`;

    const existingTemplateNames = ctx
      .getState()
      .templateOptions.map((template) => template.name)
      .filter((name) => name.startsWith(baseTemplateName));

    if (!existingTemplateNames.length) return baseTemplateName;

    const templateNumbers = existingTemplateNames.map((name) => {
      const trailingNumberRegex = /\((\d+)\)$/;
      const match = name.match(trailingNumberRegex);
      return match ? parseInt(match[1], 10) : 0;
    });

    const nextNumber = Math.max(0, ...templateNumbers) + 1;

    return `${baseTemplateName} (${nextNumber})`;
  }
}
